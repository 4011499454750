import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import localStore from '@/store/modules/local'
import sessionStore from '@/store/modules/session'

export enum routesName {
    home = '/home',
    login = '/login',
    register = '/register',
    userList = '/userList',
    carrierList = '/carrierList',
    deviceList = '/deviceList',
    userManage_carrierAdd = '/userManage/carrierAdd',
    userManage_deviceAdd = '/userManage/deviceAdd'
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue')
    },
    {
        path: '/register',
        component: () => import(/* webpackChunkName: "register" */ '../views/login/RegisterView.vue')
    },
    {
        path: '/console',
        component: () => import(/* webpackChunkName: "console" */ '../views/console/ConsoleView.vue')
    },
    {
        path: '/userList',
        component: () => import(/* webpackChunkName: "userList" */ '../views/user_manage/UserListView.vue')
    },
    {
        path: '/userManage',
        component: () => import(/* webpackChunkName: "userManage" */ '../views/user_manage/manage/UserManageView.vue'),
        children: [
            {
                path: 'userInfo',
                component: () => import(/* webpackChunkName: "userInfo" */ '../views/user_manage/manage/UserInfoView.vue')
            },
            {
                path: 'carrierAdd',
                component: () => import(/* webpackChunkName: "carrierAdd" */ '../views/user_manage/manage/CarrierAddView.vue')
            },
            {
                path: 'deviceAdd',
                component: () => import(/* webpackChunkName: "deviceAdd" */ '../views/user_manage/manage/DeviceAddView.vue')
            }
        ]
    },
    {
        path: '/carrierList',
        component: () => import(/* webpackChunkName: "carrierList" */ '../views/carrier_manage/CarrierListView.vue')
    },
    {
        path: '/containerList',
        component: () => import(/* webpackChunkName: "containerList" */ '../views/carrier_manage/ContainerListView.vue')
    },
    {
        path: '/deviceList',
        component: () => import(/* webpackChunkName: "deviceList" */ '../views/sensor_manage/DeviceListView.vue')
    },
    {
        path: '/diagnoReport',
        component: () => import(/* webpackChunkName: "diagnoReport" */ '../views/sensor_manage/DiagnoReportView.vue')
    },
    {
        path: '/paramGroup',
        component: () => import(/* webpackChunkName: "paramGroup" */ '../views/sensor_manage/ParamGroupView.vue')
    },
    {
        path: '/productList',
        component: () => import(/* webpackChunkName: "productList" */ '../views/sensor_manage/ProductListView.vue')
    },
    {
        path: '/lookPicture',
        component: () => import(/* webpackChunkName: "lookPicture" */ '../views/look_picture/LookPictureView.vue')
    },
    {
        path: '/alarmList',
        component: () => import(/* webpackChunkName: "alarmList" */ '../views/detection_manage/AlarmListView.vue')
    },
    {
        path: '/deviceWarn',
        component: () => import(/* webpackChunkName: "deviceWarn" */ '../views/detection_manage/DeviceWarnView.vue')
    },
    {
        path: '/productWarn',
        component: () => import(/* webpackChunkName: "productWarn" */ '../views/detection_manage/ProductWarnView.vue')
    },
    {
        path: '/notifiManage',
        component: () => import(/* webpackChunkName: "notifiManage" */ '../views/system_manage/NotifiManageView.vue')
    },
    {
        path: '/operaLog',
        component: () => import(/* webpackChunkName: "operaLog" */ '../views/system_manage/OperaLogView.vue')
    },
    {
        path: '/platManage',
        component: () => import(/* webpackChunkName: "platManage" */ '../views/system_manage/PlatManageView.vue')
    },
    {
        path: '/protoManage',
        component: () => import(/* webpackChunkName: "protoManage" */ '../views/system_manage/ProtoManageView.vue')
    },
    {
        path: '/maintCenter',
        component: () => import(/* webpackChunkName: "maintCenter" */ '../views/maint_center/MaintCenterView.vue')
    },
    {
        path: '/PassignPermiss',
        component: () => import(/* webpackChunkName: "PassignPermiss" */ '../views/permiss_manage/plat/AssignPermissView.vue')
    },
    {
        path: '/PpersonManage',
        component: () => import(/* webpackChunkName: "PpersonManage" */ '../views/permiss_manage/plat/PPersonManageView.vue')
    },
    {
        path: '/ProleManage',
        component: () => import(/* webpackChunkName: "ProleManage" */ '../views/permiss_manage/plat/RoleManageView.vue')
    },
    {
        path: '/personManage',
        component: () => import(/* webpackChunkName: "personManage" */ '../views/permiss_manage/PersonManageView.vue')
    },
    {
        path: '/alarmNotifi',
        component: () => import(/* webpackChunkName: "alarmNotifi" */ '../views/system_manage/AlarmNotifiView.vue')
    },
    {
        path: '/dataCenter',
        component: () => import(/* webpackChunkName: "dataCenter" */ '../views/data_center/DataCenterView.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const token = localStore.state.token || sessionStore.state.token
    document.title = localStore.state.platformInfo.name
    changeFavicon(localStore.state.platformInfo.image)
    if (token || to.path === routesName.login || to.path === routesName.register) {
        if (to.path !== routesName.login && to.path !== routesName.register &&
            localStore.state.menuListAllRoutes?.findIndex(item => item === `#${top!.location.href.split('/#')[1]}`) !== -1) {
            top!.location.href = '#' + routesName.home
            next(routesName.home)
        } else {
            next()
        }
    } else {
        top!.location.href = '#' + routesName.login
        next(routesName.login)
    }
})

function changeFavicon (link: string) {
    let $favicon: any = document.querySelector('link[rel="icon"]')
    if ($favicon !== null) {
        $favicon.href = link
    } else {
        $favicon = document.createElement('link')
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
    }
}

export default router
